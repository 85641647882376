import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const TimelineData = [
  {
    id: '1',
    date: 'Module 1',
    title: 'Earth: My Values & Form',
    description:
      'All about values and living in alignment. We will be looking at your natal chart, to get guidance from the stars. We also will be looking at how to make space to invite inner peace, and we start making a connection with Tea as plant medicine.',
    image: 'timeline-01',
  },
  {
    id: '2',
    date: 'Module 2',
    title: 'Water: My Flow ',
    description:
      'The formal Tea Ceremony will be introduced. We will be going over the 5 basics, all the movements and the meaning behind them! We will sit together in your very first Tea ritual!',
    image: 'timeline-02',
  },
  {
    id: '3',
    date: 'Module 3',
    title: 'Fire: My Passion',
    description:
      'A deep dive deep into subconscious patterns, our connection to the world around us. And we will look at the qualities of Tea as a plant medicine, the importance of water, the different options for fire and more.',
    image: 'timeline-03',
  },
  {
    id: '4',
    date: 'Module 4',
    title: 'Air: My Dreams',
    description:
      'We will talk about the 8 cups of Life with Tea, and you’ll have all the time you need to ask any questions that you may have. We will also talk about Chaxi!',
    image: 'timeline-04',
  },
  {
    id: '5',
    date: 'Module 5',
    title: 'Ether: My Spirit',
    description:
      'We will connect to Ritual, and talk about specific Rituals for every day of the week based on the stars, building your Sacred Altar, Sacred medicines and more!.',
    image: 'timeline-05',
  },
];

const TimelineOneEN = () => {
  return (
    <div className='rn-timeline-wrapper timeline-style-one position-relative'>
      <div className='timeline-line'></div>
      {/* Start Single Timeline  */}

      {TimelineData.map((data, index) => (
        <ScrollAnimation
          animateIn='fadeInUp'
          animateOut='fadeInOut'
          animateOnce={true}
        >
          <div className='single-timeline mt--50' key={index}>
            <div className='timeline-dot'>
              <div className='time-line-circle'></div>
            </div>
            <div className='single-content'>
              <div className='inner'>
                <div className='row row--30 align-items-center'>
                  <div className='order-2 order-lg-1 col-lg-6 mt_md--40 mt_sm--40'>
                    <div className='content'>
                      <span className='date-of-timeline'>{data.date}</span>

                      <h2 className='title'>{data.title}</h2>

                      <p className='description'>{data.description}</p>
                    </div>
                  </div>
                  <div className='order-1 order-lg-2 col-lg-6'>
                    <div className='thumbnail'>
                      <img
                        style={{
                          borderStyle: 'solid',
                          borderWidth: '10px',
                          borderImage:
                            'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                        }}
                        className='w-100'
                        src={`./images/timeline/${data.image}.webp`}
                        alt='Corporate React Template'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      ))}

      {/* End Single Timeline  */}
    </div>
  );
};

export default TimelineOneEN;
