import React from 'react';
import { FiCheck } from 'react-icons/fi';

const PricingThree = () => {
  return (
    <div className='row'>
      <div className='col-lg-8 offset-lg-2'>
        <div className='row row--0'>
          {/* Start PRicing Table Area  */}
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='rn-pricing style-2'>
              <div className='pricing-table-inner'>
                <div className='pricing-header'>
                  <h4 className='title'>BIRTH PREP</h4>
                  <div className='pricing'>
                    <div className='price-wrapper'>
                      <span className='currency'>$</span>
                      <span className='price'>444</span>
                    </div>
                    <span className='subtitle'>USD</span>
                  </div>
                </div>
                <div className='pricing-body'>
                  <ul className='list-style--1'>
                    <li>
                      <FiCheck /> 2 Months
                    </li>
                    <li>
                      <FiCheck /> Connection call
                    </li>
                    <li>
                      <FiCheck /> 2 consultations a month
                    </li>
                    <li>
                      <FiCheck /> Fertility advise
                    </li>
                    <li>
                      <FiCheck /> Cycle Analysis
                    </li>
                    <li>
                      <FiCheck /> Pre-natal advise
                    </li>
                  </ul>
                </div>
                <div className='pricing-footer'>
                  <a
                    className='btn-default btn-border'
                    target='_blank'
                    href='https://checkoutpage.co/c/bloomwithtea/birth-prep'
                  >
                    PURCHASE
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}

          {/* Start PRicing Table Area  */}
          <div className='col-lg-6 col-md-6 col-12'>
            <div className='rn-pricing style-2 active'>
              <div className='pricing-table-inner'>
                <div className='pricing-header'>
                  <h4 className='title'>BIRTH SUPPORT</h4>
                  <div className='pricing'>
                    <div className='price-wrapper'>
                      <span className='currency'>$</span>
                      <span className='price'>1,111</span>
                    </div>
                    <span className='subtitle'>USD</span>
                  </div>
                </div>
                <div className='pricing-body'>
                  <ul className='list-style--1'>
                    <li>
                      <FiCheck /> 6 Months
                    </li>
                    <li>
                      <FiCheck /> Connection call
                    </li>
                    <li>
                      <FiCheck /> 2 consultations a month
                    </li>
                    <li>
                      <FiCheck /> Daily support during working hours through a
                      group chat
                    </li>
                    <li>
                      <FiCheck /> Birth plan
                    </li>
                    {/*  <li>
                      <FiCheck /> 24/7 stand-by from 38th week
  </li> */}
                    <li>
                      <FiCheck /> 44 Days of post-partum support
                    </li>
                    <li>
                      <FiCheck /> Breastfeeding support
                    </li>
                    <li>
                      <FiCheck /> Baby massage workshop
                    </li>
                  </ul>
                </div>
                <div className='pricing-footer'>
                  <a
                    className='btn-default'
                    target='_blank'
                    href='https://checkoutpage.co/c/bloomwithtea/birth-support'
                  >
                    PURCHASE
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* End PRicing Table Area  */}
        </div>
      </div>
    </div>
  );
};
export default PricingThree;
