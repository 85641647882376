import React from 'react';
import SEO from '../../common/SEO';
import SectionTitle from '../sectionTitle/SectionTitle';
import One from './One';

const Offerings = () => {
  return (
    <>
      <SEO title='Tea Ceremony & Ritual Way of Life' />
      <div className='main-content'>
        <div className='rwt-portfolio-area rn-section-gap'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitle
                  textAlign='text-center'
                  textAlignTitle='text-center'
                  radiusRounded=''
                  title='OFFERINGS'
                  description=''
                />
              </div>
            </div>
            <One Column='col-sm-3 col-md-5 mt--30 portfolio' />
          </div>
        </div>
      </div>
    </>
  );
};
export default Offerings;
