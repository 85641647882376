import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import TabThree from '../elements/tab/TabThree';
import ScrollTop from '../common/footer/ScrollTop';

const Deck = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />

        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          {/* Start Elements Area  */}
          <div id='deck' className='rwt-tab-area rn-section-gap'>
            <div className='container'>
              <div className='row mb--40'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-left'
                    radiusRounded=''
                    subtitle='Cosmic Answer'
                    title='Affirmation Deck'
                    description=''
                  />
                  <div>
                    <strong>100% unique, handcrafted and fully digital!</strong>{' '}
                    Lifelong access to my <strong>Cosmic Answer </strong>deck of
                    44 cards that: <br />
                    🌟 helps you to align body, mind, soul, and heart!
                    <br /> 🌟 helps you to tap into the wisdom available to you.
                    <br />
                    🌟 helps you to connect with the world of Nature and the
                    elements Water, Earth, Air, and Fire.
                    <br /> 🌟helps you to uncover blocks within you. <br />
                    You can use this deck before, during or after a Tea sit, or
                    when you desire some guidance.
                    <div className='read-more-btn'>
                      <a
                        className='btn-default mb-5 mt-5'
                        target='_blank'
                        href='https://checkoutpage.co/c/bloomwithtea/cosmic-answer'
                      >
                        <span> BUY THE DECK</span>
                      </a>
                    </div>
                    <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                      In the app you'll also find a link to the special 'Bloom
                      with Tea' Spotify playlist that you can use during your
                      Rituals! With almost 14 hours of music, and ever-growing!
                    </span>
                    <br /> <br />
                    <a
                      style={{ color: '#65676b' }}
                      target='_blank'
                      href='https://bloomwithtea.netlify.app/'
                    >
                      {' '}
                      <strong>GO TO THE LOGIN PORTAL!</strong>
                    </a>{' '}
                  </div>
                </div>
              </div>
              <TabThree />
              <div style={{ padding: '25px 0' }}>
                <a class='inline-button' href='/workwithme'>
                  {' '}
                  <strong>&larr; ALL OFFERINGS</strong>
                </a>{' '}
              </div>
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default Deck;
