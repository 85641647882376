import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import PageScrollTop from './components/pageToTop/PageScrollTop';
import About from './pages/About';
import HomeEN from './pages/HomeEN';
import Error from './pages/Error';
import TC from './pages/TC';
import HeaderTopBar from './common/header/HeaderTopBar';

// Import Css Here
import './assets/scss/style.scss';
import WorkWithMe from './pages/WorkWithMe';
import Deck from './pages/Deck';
import Connect from './pages/Connect';
import Reading from './pages/Reading';
import TeaCeremony from './pages/TeaCeremony';
import BirthKeeper from './pages/BirthKeeper';

const App = () => {
  let { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change

  return (
    <PageScrollTop>
      <HeaderTopBar />
      <Switch>
        <Route
          path={`${process.env.PUBLIC_URL + '/'}`}
          exact
          component={HomeEN}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/about'}`}
          exact
          component={About}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/workwithme'}`}
          exact
          component={WorkWithMe}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/teaceremony'}`}
          exact
          component={TeaCeremony}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/deck'}`}
          exact
          component={Deck}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/astrologyreading'}`}
          exact
          component={Reading}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/birthkeeper'}`}
          exact
          component={BirthKeeper}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/connect'}`}
          exact
          component={Connect}
        />
        <Route
          path={`${process.env.PUBLIC_URL + '/termsandconditions'}`}
          exact
          component={TC}
        />

        <Route component={Error} />
      </Switch>
    </PageScrollTop>
  );
};

export default App;
