import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import { Link } from 'react-router-dom';
import { FiArrowRight, FiCheck } from 'react-icons/fi';

const AboutTwo = () => {
  return (
    <div className='rwt-about-area about-style-2 rn-section-gap'>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <img
                className='w-100'
                src='./images/about/pregnant-belly.webp'
                alt='About Images'
              />
            </div>
          </div>

          <div className='col-lg-7 mt_md--30 mt_sm--30'>
            <div className='content'>
              <div className='section-title'>
                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  <h2 className='title mt--10'>HOW I CAN SUPPORT YOU.</h2>
                </ScrollAnimation>

                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  <p
                    style={{
                      fontFamily: 'PT Serif',
                      fontSize: '20px',
                      marginBottom: '10px',
                    }}
                  >
                    Bringing new Life into this world is a Sacred initiation. An
                    initiation into Motherhood and the start of a new chapter of
                    your Spiritual Journey.
                  </p>
                  <p>
                    Every woman deserves a positive birth experience. And though
                    we are not here to control the outcome, as we simply
                    cannot.. I can support you in having a birth experience that
                    is in alignment with YOUR truth. By preparing and
                    understanding all of your options, full with self-confidence
                    you will be able to focus on the most important thing:
                    birthing your baby with all your innate power, widsom, and
                    infinite love.
                  </p>

                  {/* <ul className='list-icon'>
                    <li>
                      <span className='icon'>
                        <FiCheck />
                      </span>{' '}
                      Fertility & Pre-natal advise
                    </li>
                    <li>
                      <span className='icon'>
                        <FiCheck />
                      </span>{' '}
                      Birth planning
                    </li>
                    <li>
                      <span className='icon'>
                        <FiCheck />
                      </span>{' '}
                      Birth support
                    </li>
                    <li>
                      <span className='icon'>
                        <FiCheck />
                      </span>{' '}
                      Birth photography
                    </li>
                    <li>
                      <span className='icon'>
                        <FiCheck />
                      </span>{' '}
                      Post-partum support
                    </li>
                  </ul>
  */}
                </ScrollAnimation>
                <ScrollAnimation
                  animateIn='fadeInUp'
                  animateOut='fadeInOut'
                  animateOnce={true}
                >
                  {/*     <div className='read-more-btn mt--40'>
                    <Link className='btn-default btn-icon' to='#'>
                      More About Us{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
  </div> */}
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutTwo;
