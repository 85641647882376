import React from 'react';
import Typed from 'react-typed';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Separator from '../elements/separator/Separator';
import AboutTwo from '../elements/about/AboutTwo';
import Pricing from '../elements/pricing/Pricing';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import ServiceFour from '../elements/service/ServiceFour';

const BirthKeeper = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />

        {/* Start Slider Area  */}
        <div className='slider-area slider-style-1 bg-transparent variation-2 height-750'>
          <div className='container' style={{ maxWidth: '92%' }}>
            <div className='row'>
              <div className='col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40'>
                <div className='inner text-start'>
                  <h1
                    className='title theme-gradient display-two'
                    style={{ fontFamily: 'Magic' }}
                  >
                    BIRTH <br /> KEEPER
                    {/*  <br />
                    <Typed
                      strings={['KEEPER.', 'DOULA.', 'CONSULTANT.']}
                      typeSpeed={80}
                      backSpeed={5}
                      backDelay={1000}
                      loop
                    /> 
  */}
                  </h1>
                  <p className='description'>
                    I hold space for women to connect to the Sacredness of their
                    innate wisdom codes & power during their initiation into
                    Motherhood!
                  </p>
                </div>
              </div>
              <div className='col-lg-5 order-1 order-lg-2'>
                <div className='thumbnail'>
                  <img
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '-111px',
                      right: '-88px',
                      height: '250px',
                      width: '250px',
                    }}
                    className='img-flowers w-80'
                    src='./images/about/flowers-left-top.webp'
                    alt='flowers'
                  />
                  <img
                    style={{
                      padding: '0px',
                      borderStyle: 'solid',
                      position: 'relative',
                      borderWidth: '10px',
                      zIndex: '2',
                      borderImage:
                        'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                    }}
                    src='./images/about/birthkeeper_about-squared.webp'
                    alt='Banner Images'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div style={{ margin: '0 auto' }}>
          <Separator />

          {/* Start Service Area  */}
          <div className='rn-service-area rn-section-gap'>
            <div className='container'>
              <div className='row' style={{ justifyContent: 'center' }}>
                <div className='col-lg-7'>
                  <SectionTitle
                    textAlign='text-justify'
                    textAlignTitle='text-center'
                    radiusRounded=''
                    subtitle=''
                    title='Values I stand for.'
                    description='Through a long Journey from the academic world filled with illogical logic back into my own Heart, I have learned that my obtained medical BA Honours Degree, Master of Science in Epidemiology, and Midwifery Training in order to obtain a license is nothing more than a facade. A facade I am not willing to hide behind anymore.
                    <br/> <br/> Bravely I choose to stand by my values and go my own way by providing the care that all women deserve and nothing less. '
                  />
                </div>
              </div>
              <ServiceFour
                serviceStyle='service__style--1 icon-circle-style with-working-process'
                textAlign='text-center'
              />
            </div>
          </div>
          {/* End Service Area  */}

          <Separator />
          <AboutTwo />

          <Separator />
          {/* Start Elements Area  */}
          <div className='rwt-pricing-area rn-section-gap'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle=''
                    title='Birth Packages'
                    description=''
                  />
                </div>
              </div>
              <Pricing />
              <div style={{ padding: '25px 0' }}>
                <a class='inline-button' href='/workwithme'>
                  {' '}
                  <strong>&larr; ALL OFFERINGS</strong>
                </a>{' '}
              </div>
            </div>
          </div>
          {/* End Elements Area  */}
        </div>
      </main>
    </>
  );
};

export default BirthKeeper;
