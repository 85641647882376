import React from 'react';

const SectionTitle = ({
  subtitle,
  title,
  description,
  textAlign,
  textAlignTitle,
  radiusRounded,
}) => {
  return (
    <div className={`section-title ${textAlignTitle}`}>
      <h2
        style={{
          textTransform: 'uppercase',
        }}
        className='title w-600 mb--20'
        dangerouslySetInnerHTML={{ __html: title }}
      ></h2>

      <p
        className={`description b1 ${textAlign}`}
        dangerouslySetInnerHTML={{ __html: description }}
      ></p>
    </div>
  );
};
export default SectionTitle;
