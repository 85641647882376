import React from 'react';

const SectionTitleTwo = ({ Title, textAlign }) => {
  return (
    <div className={`section-title-2 ${textAlign}`}>
      <h2
        style={{ margin: '0', padding: '0', border: 'none' }}
        className='title'
      >
        {Title}
      </h2>
    </div>
  );
};
export default SectionTitleTwo;
