import PropTypes from 'prop-types';
import React from 'react';

const SEOEN = ({ title }) => {
  return (
    <>
      <meta charSet='utf-8' />
      <title>{title} || Tea Ceremony & Ritual Way of Life</title>
      <meta name='robots' content='noindex, follow' />
      <meta
        name='description'
        content='Tea Ceremony & Ritual Way of Life! Invite Tea, ceremony, and the margin world of Nature into your life and uncover inner peace & abundance!'
      />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
    </>
  );
};
SEOEN.propTypes = {
  title: PropTypes.string,
};

export default SEOEN;
