import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const callToActionData = {
  title: 'ARE YOU READY?',
  btnText: 'I AM READY!',
};

const CalltoActionTwoEN = () => {
  return (
    <>
      <div class='scrolling-text'>
        • Tea is Calling • Tea is Calling • Tea is Calling • Tea is Calling •
        Tea is Calling • Tea is Calling • Tea is Callingn • Tea is Calling • Tea
        is Calling • Tea is Calling •
      </div>
      <div className='rn-callto-action clltoaction-style-default style-5'>
        <div className='container'>
          <div className='row row--0 align-items-center content-wrapper theme-shape'>
            <div className='col-lg-12'>
              <div className='inner'>
                <div className='content text-center'>
                  <h2 className='title'>{callToActionData.title}</h2>
                  <h5
                    style={{ fontFamily: 'Montserrat', fontSize: '20px' }}
                    className='subtitle'
                  >
                    To dive deep into the world of Tea & Ritual!
                    <br /> <br />
                    <strong style={{ fontSize: '14px', fontStyle: 'italic' }}>
                      *This offering is only open by application. <br />
                    </strong>
                  </h5>
                  <br />
                  <div className='call-to-btn text-center'>
                    <a
                      target='_blank'
                      className='btn-default btn-icon'
                      href='https://bloomwithtea.ck.page/waitlist'
                    >
                      APPLY HERE{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </a>
                  </div>{' '}
                  <br />
                  {/*
               <div className='call-to-btn text-center'>
                  <a
                    target='_blank'
                    className='btn-default btn-icon'
                    href='https://checkoutpage.co/c/bloomwithtea/tea-and-ritual'
                  >
                    PAY IN FULL{' '}
                    <i className='icon'>
                      <FiArrowRight />
                    </i>
                  </a>
                </div>{' '}
                <br />
                <div className='call-to-btn text-center'>
                  <a
                    target='_blank'
                    className='btn-default btn-icon'
                    href='https://checkoutpage.co/c/bloomwithtea/tea-and-ritual-payment-plan'
                  >
                    PAY IN INSTALLMENTS{' '}
                    <i className='icon'>
                      <FiArrowRight />
                    </i>
                  </a>
                </div> 
              */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CalltoActionTwoEN;
