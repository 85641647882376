import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import AboutOneEN from '../elements/about/AboutOneEN';
import ScrollTop from '../common/footer/ScrollTop';
import { Link } from 'react-router-dom';
import AboutMeThree from '../elements/about/AboutMeThree';
import NewsletterEN from '../elements/calltoaction/NewsletterEN';

const About = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />

        {/* Start About Area  */}
        <AboutOneEN />
        <AboutMeThree />

        <div
          style={{ display: 'flex', justifyContent: 'center' }}
          className='read-more-btn mt--20 mb--40'
        >
          <Link to='/teaceremony' className='btn-default'>
            <span>I AM READY FOR TEA</span>
          </Link>
        </div>

        {/* Start Call To Action Area  */}
        <div className='rwt-callto-action-area mb--40'>
          <div className='wrapper'>
            <NewsletterEN />
          </div>
        </div>
        {/* End Call To Action Area  */}
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default About;
