import React from 'react';
import { Link } from 'react-router-dom';

const AboutMeTwo = () => {
  return (
    <div className=''>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <img
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '-75px',
                  left: '-75px',
                  height: '250px',
                  width: '250px',
                }}
                className='img-flowers w-80'
                src='./images/about/flowers-left-top-2.webp'
                alt='flowers'
              />
              <img
                style={{
                  borderStyle: 'solid',
                  position: 'relative',
                  borderWidth: '10px',
                  zIndex: '2',
                  borderImage:
                    'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                }}
                className='w-100'
                src='./images/about/aboutmetwo.webp'
                alt='About Images'
              />
            </div>
          </div>

          <div className='col-lg-7 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <p
                  style={{
                    fontFamily: 'PT Serif',
                    fontSize: '20px',
                    marginBottom: '10px',
                  }}
                >
                  My Prayer is for you to know with all of your Being that you
                  are <strong>DIVINE.</strong> For you to feel connected to all
                  of Life around you, to feel supported, and to know in your
                  Heart that you are <strong>never alone</strong>.
                </p>
                <br />
                <p>
                  Being a Human, here on Mother Earth, can get hard... Often my
                  Heart wheeps with grief as much as it overflows with joy! Tea
                  holds me in a warm embrace in the difficult moments. She
                  continuously invites me in to cultivate my inner peace. Now
                  it's my turn to invite you to experience{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    inner peace every single day{' '}
                  </span>{' '}
                  by connecting{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    with yourself through Nature!
                  </span>{' '}
                </p>
                <div className='read-more-btn mt--40'>
                  <Link to={'/about'}>
                    {' '}
                    <a className='btn-default'>
                      <span>MORE ON MY STORY HERE</span>
                    </a>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 style={{ textAlign: 'center', padding: '50px 5px' }}>
        {' '}
        I CANNOT WAIT TO INTRODUCE YOU TO TEA!
      </h2>
    </div>
  );
};

export default AboutMeTwo;
