import React from 'react';
import { FiArrowRight } from 'react-icons/fi';

const callToActionData = {
  title: 'TAP INTO YOUR INNATE ABUNDANCE!',
  btnText: 'READ MY STARS!',
};

const CalltoActionENTWO = () => {
  return (
    <div
      className='rn-callto-action clltoaction-style-default style-5'
      style={{ margin: '25px 0 50px 0' }}
    >
      <div className='container'>
        <div className='row row--0 align-items-center content-wrapper theme-shape'>
          <div className='col-lg-12'>
            <div className='inner'>
              <div className='content text-center'>
                <h2 className='title'>{callToActionData.title}</h2>

                <p style={{ fontSize: '20px' }} className='subtitle'>
                  ✨ Feeling a bit stuck or lost in Life? <br />✨ Searching for
                  you soul's purpose? <br />✨ Looking to call in abundance?{' '}
                  <br />✨ Trying to connect to your innate gifts? <br /> ✨ Or
                  maybe just curious about what's in your stars? <br />
                </p>
                <br />

                <div></div>

                <div className='call-to-btn text-center'>
                  <a
                    target='_blank'
                    className='btn-default btn-icon'
                    href='https://checkoutpage.co/c/bloomwithtea/uncover-your-purpose-astrology-reading'
                  >
                    {callToActionData.btnText}{' '}
                    <i className='icon'>
                      <FiArrowRight />
                    </i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalltoActionENTWO;
