import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import ScrollTop from '../common/footer/ScrollTop';
import Offerings from '../elements/offerings/offerings';
import NewsletterEN from '../elements/calltoaction/NewsletterEN';

const WorkWithMe = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />
        <div
          style={{
            maxWidth: '80vh',
            margin: '0 auto',
            position: 'relative',
            zIndex: '2',
          }}
        >
          <Offerings />
        </div>
        {/* <div style={{ display: 'flex' }}>
          <img
            style={{
              width: '33%',
              margin: '-55px auto 25px',
              padding: '0',
              borderStyle: 'solid',
              position: 'relative',
              borderWidth: '10px',
              zIndex: '1',
              borderImage:
                'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
            }}
            alt='walking together'
            src={`${process.env.PUBLIC_URL}/images/about/temple_video.gif`}
          />
        </div>*/}

        {/* Start Call To Action Area  
        <div className='rwt-callto-action-area mb--40'>
          <div className='wrapper'>
            <NewsletterEN />
          </div>
        </div>*/}
        {/* End Call To Action Area  */}
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default WorkWithMe;
