import React from 'react';
import SubscribeForm from './SubscribeForm';

const NewsletterEN = () => {
  return (
    <div className='rn-callto-action clltoaction-style-default style-5 my-4'>
      <div className='container'>
        <div
          className='row row--0 align-items-center justify-content-center content-wrapper theme-shape'
          style={{ maxWidth: '100%' }}
        >
          <div className='col-lg-5'>
            <div className='inner'>
              <div className='content text-center'>
                <img
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'center',
                    margin: '0 auto',
                    padding: '0 0 15px',
                  }}
                  height='250px'
                  width='250px'
                  src={`${process.env.PUBLIC_URL}/images/logo/avatar.png`}
                  alt='avatar'
                />
                <h2
                  style={{
                    margin: '0',
                  }}
                  className='title'
                >
                  STAY CONNECTED
                </h2>
                <h6
                  style={{ fontFamily: 'Montserrat', fontSize: '16px' }}
                  className='subtitle'
                >
                  Receive love-encoded Tea Whispers, and special gifts from my
                  Heart straight to your inbox!
                </h6>

                <br />
                <br />

                <SubscribeForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NewsletterEN;
