import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useForm as useFormSpree } from '@formspree/react';

const Result = () => {
  return (
    <p className='success-message'>
      Your Message has been successfully sent. I will contact you soon.
    </p>
  );
};

function ContactFormEN({ props, formStyle }) {
  const [result, showresult] = useState(false);
  const {
    register,
    formState: { errors },
  } = useForm();

  const [state, handleSubmit] = useFormSpree('mpzkevpp');
  if (state.succeeded) {
    return (
      <>
        <p>Thanks love! I will reply to your message soon ❤</p> <br />
      </>
    );
  }

  setTimeout(() => {
    showresult(false);
  }, 5000);

  return (
    <form className={`${formStyle}`} action='' onSubmit={handleSubmit}>
      <div className='form-group'>
        <input type='text' name='fullname' placeholder='Name' required />
      </div>

      <div className='form-group'>
        <input type='email' name='email' placeholder='Email' required />
      </div>

      <div className='form-group'>
        <textarea name='message' placeholder='Message' required></textarea>
      </div>

      <div className='form-group'>
        <button className='btn-default btn-large'>SEND</button>
      </div>

      <div className='form-group'>{result ? <Result /> : null}</div>
    </form>
  );
}
export default ContactFormEN;
