import React from 'react';
import {
  FiFacebook,
  FiTwitter,
  FiInstagram,
  FiLinkedin,
  FiChevronRight,
  FiMapPin,
  FiPhone,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

const HeaderTopBar = () => {
  return (
    <div
      className='header-top-bar'
      style={{ backgroundImage: "url('/images/banner/banner_pink.webp')" }}
    >
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-lg-12 col-md-12 col-12'>
            <div className='header-left'>
              <p>
                <a
                  target='_blank'
                  href='https://bloomwithtea.ck.page/teawhispers'
                  style={{
                    color: 'white',
                    fontWeight: 'bold',
                    textTransform: 'uppercase',
                    fontSize: '14px',
                  }}
                >
                  Get a free meditation and Journey into your Heart with me!{' '}
                  <FiChevronRight />
                </a>
              </p>
            </div>
          </div>
          {/*  <div className='col-lg-8 col-md-12 col-12'>
            <div className='header-right'>
              <div className='social-icon-wrapper'>
                <ul className='social-icon social-default icon-naked'>
                  <li>
                    {' '}
                    <span
                      style={{
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: 'bold',
                      }}
                    >
                      {' '}
                      FOLLOW MY JOURNEY{' '}
                    </span>{' '}
                    <a
                      target='_blank'
                      rel='noreferrer'
                      href='https://www.instagram.com/bloomwithtea'
                    >
                      {' '}
                      <FiInstagram
                        style={{
                          color: 'white',
                        }}
                      />{' '}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>*/}
        </div>
      </div>
    </div>
  );
};

export default HeaderTopBar;
