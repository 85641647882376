import React from 'react';

const AboutOneEN = () => {
  return (
    <div className='rwt-about-area rn-section-gap'>
      <div className='container'>
        <h1
          className='title'
          style={{
            margin: '0 auto',
            textAlign: 'center',
            fontFamily: 'Magic',
          }}
        >
          TEA CEREMONIALIST, ALCHEMIST, & LOVER
        </h1>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-4'>
            <div className='thumbnail'>
              <img
                style={{
                  borderStyle: 'solid',
                  borderWidth: '10px',
                  borderImage:
                    'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                }}
                className='w-100'
                src='./images/about/astro_bloom.webp'
                alt='About Images'
              />
            </div>
          </div>

          <div className='col-lg-8 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <p
                  style={{
                    fontFamily: 'PT Serif',
                    fontSize: '20px',
                    marginBottom: '10px',
                  }}
                >
                  In the 29 years that I’ve been wandering on this beautiful
                  Earth 🌍 I’ve lived many lives.
                </p>
                <p>
                  From being trained as an academic researcher and
                  epidemiologist across the Netherlands and Canada, to studying
                  and teaching yoga in India, to selling handmade crystal
                  infused jewellery and practising Reiki in Nepal, to chasing my
                  love for women’s health by going back to school and studying
                  midwifery, to working as a designer and developer because the
                  healthcare world drove me insane (a lot to say about
                  this...!), to finally finding the courage to claim my Life's
                  mission and pave my own way by guiding women into world of Tea
                  & Ritual!
                </p>
                <br />
                <p>
                  I could never keep steady ground beneath my feet. Always
                  searching for something new to do, something new to learn,
                  another book to read, another training to complete. Move from
                  one country to another one, jump from job to job, from study
                  to study, from partner to partner.. Subconsiously, always
                  looking for something that would give me inner peace. ✨ And
                  at the same time always fighting to survive.. Never feeling
                  like I could ever be fully accepted for being myself, forever
                  hiding essential parts of myself and always living on the
                  edge... this continued till my body just yelled STOP. I landed
                  in the hospital in a strange country and had panic attacks
                  with the slightest things. These attacked made me face Death
                  with suffocating fear, and then Tea came.. and I surrendered
                  to Life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutOneEN;
