import PropTypes from 'prop-types';
import React from 'react';

const SEO = ({ title }) => {
  return (
    <>
      <meta charSet='utf-8' />
      <title>{title} || Thee Ceremonie & Ritual Way of Life </title>
      <meta name='robots' content='noindex, follow' />
      <meta
        name='description'
        content='Tea Ceremony & Ritual Way of Life'
      />
      <meta
        name='viewport'
        content='width=device-width, initial-scale=1, shrink-to-fit=no'
      />
    </>
  );
};
SEO.propTypes = {
  title: PropTypes.string,
};

export default SEO;
