import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const dataList = [
  {
    image: '/images/tab/Water_2.webp',
  },
  {
    image: '/images/tab/Earth_7.webp',
  },
];

const dataList2 = [
  {
    image: '/images/tab/Air_1.webp',
  },
  {
    image: '/images/tab/Fire_2.webp',
  },
];

const dataList3 = [
  {
    image: '/images/tab/Backside.webp',
  },
  {
    image: '/images/tab/Intro.webp',
  },
];

const TabThree = () => {
  return (
    <div>
      <div className='row'>
        <div className='col-lg-12'>
          <Tabs>
            <div className='row row--30 align-items-center'>
              <div className='col-lg-12'>
                <div className='rn-default-tab style-three'>
                  <div className='tab-button-panel'>
                    <TabList className='tab-button'>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Intro</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Water & Earth</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className='rn-tab-button'>
                          <button>Air & Fire</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className='tab-content-panel'>
                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <div className='row align-items-center row--30'>
                            <div className='col-lg-8 mt_md--30 mt_sm--30'>
                              <div className='row row--15 mt_dec--30 service-wrapper'>
                                {dataList3.map((val, i) => (
                                  <div
                                    className='col-lg-6 col-md-6 col-sm-6 col-12'
                                    key={i}
                                  >
                                    <div className='card-box card-style-1 text-left'>
                                      <div className='inner'>
                                        <div className='image'>
                                          <img
                                            src={`${val.image}`}
                                            alt='card Images'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <div className='row align-items-center row--30'>
                            <div className='col-lg-8 mt_md--30 mt_sm--30'>
                              <div className='row row--15 mt_dec--30 service-wrapper'>
                                {dataList.map((val, i) => (
                                  <div
                                    className='col-lg-6 col-md-6 col-sm-6 col-12'
                                    key={i}
                                  >
                                    <div className='card-box card-style-1 text-left'>
                                      <div className='inner'>
                                        <div className='image'>
                                          <img
                                            src={`${val.image}`}
                                            alt='card Images'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className='rn-tab-content'>
                        <div className='inner'>
                          <div className='row align-items-center row--30'>
                            <div className='col-lg-8 mt_md--30 mt_sm--30'>
                              <div className='row row--15 mt_dec--30 service-wrapper'>
                                {dataList2.map((val, i) => (
                                  <div
                                    className='col-lg-6 col-md-6 col-sm-6 col-12'
                                    key={i}
                                  >
                                    <div className='card-box card-style-1 text-left'>
                                      <div className='inner'>
                                        <div className='image'>
                                          <img
                                            src={`${val.image}`}
                                            alt='card Images'
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabThree;
