import React from 'react';
import Layout from '../common/Layout';

const TC = () => {
  return (
    <Layout>
      <div className='error-area ptb_sm--60 ptb_md--80'>
        <div className='container'>
          <div className='row align-item-center'>
            <div className='col-lg-12'>
              <div className='error-inner'>
                <div style={{ padding: '50px' }}>
                  <h2 style={{ textAlign: 'center' }}>
                    <b>Terms & Conditions</b>
                  </h2>
                  <p style={{ fontWeight: '700' }}>
                    PLEASE READ THESE TERMS OF USE FULLY AND CAREFULLY BEFORE
                    USING https://www.bloomwithtea.com/ (THE "SITE") AS THE
                    CLIENT ("YOU"). IT CONTAINS VERY IMPORTANT INFORMATION ABOUT
                    YOUR LEGAL RIGHTS AND OBLIGATIONS, INCLUDING LIMITATIONS OF
                    YOUR RIGHTS AND EXCLUSIONS THAT MAY APPLY TO YOU. THESE
                    TERMS SET FORTH LEGALLY BINDING TERMS AND CONDITIONS FOR USE
                    OF THE SITE AND WORKING TOGETHER.
                  </p>
                  <h3>Acceptance of Terms</h3>
                  <p>
                    {' '}
                    By browsing and using the Site, you agree to these Terms and
                    all other operating rules, policies, and procedures that may
                    be published from time to time on the Site, each of which is
                    incorporated by reference.{' '}
                  </p>
                  <h3>Eligibility to Use the Site</h3>
                  <p>
                    You may use the Site only if you are eighteen (18) years or
                    older and capable of forming a binding contract with us and
                    are not barred from using the Site under any applicable law.
                    As long as you comply with these Terms, you may view and use
                    our Site for your personal, non-commercial purposes. No
                    other use of the Site is authorized.
                  </p>
                  <h3>International Users</h3>
                  <p>
                    Our Site is controlled, operated, and administered from our
                    offices in Belgium, EU. We make no representation that
                    Content on the Site is appropriate or available for use at
                    other locations outside the EU, and access to Content is
                    prohibited from territories where the Content or products
                    available through the Site are illegal. You may not use the
                    Site or export its Content or products in violation of EU
                    export laws and regulations. If you access the Site from a
                    location outside of the EU, you are responsible for
                    compliance with all local laws if, and to the extent, local
                    laws are applicable.
                  </p>
                  <h3>Change to Terms</h3>
                  <p>
                    We reserve the right to make changes to these Terms at any
                    time, and such changes will be effective immediately upon
                    being posted on the Site. Each time you use the Site, you
                    should review the current Terms. You can determine when
                    these Terms were last revised by referring to the "LAST
                    UPDATED" legend at the top of these Terms of Use. Your
                    continued use of the Site will constitute your acceptance of
                    the current Terms; however, any material change to these
                    Terms after your last usage of the Site will not be applied
                    retroactively. Except for such material changes, the Terms
                    that were in effect at the time any claim or dispute arose
                    between you and "THE SITE" will be applied.
                  </p>
                  <h3>Use of E-mail</h3>
                  <p>
                    You may be required to register your email with "THE SITE"
                    in order to gain access to her mailing list. In addition to
                    all other rights available to "THE SITE" including those set
                    forth in these Terms, we reserve the right, in our sole
                    discretion to terminate your account or your use of the
                    Site, block or prevent future access to and use of the Site,
                    refuse service to you, or cancel your product orders (i) if
                    you violate any of these Terms; (ii) for any other reason;
                    or (iii) for no reason.
                  </p>
                  <h3>Rules of Conduct & General Prohibitions</h3>
                  <p>
                    You must only use this Site for lawful purposes, and you
                    must not use it in any way that infringes the rights of
                    anyone else or that restricts or inhibits anyone else’s
                    enjoyment of the Site. You may not, without our written
                    consent (i) copy, reproduce, use, or otherwise deal with any
                    Content on the Site; (ii) modify, distribute, or re-post any
                    Content on the Site for any purpose; or (iii) use the
                    Content of the Site for any commercial exploitation
                    whatsoever. In using the Site, you further agree not to do
                    any of the following: post, publish, submit, or otherwise
                    transmit any Content that (i) infringes, misappropriates, or
                    violates a third party’s patent, copyright, trademark, trade
                    secret, or other intellectual property rights, or rights of
                    publicity or privacy; (ii) is knowingly false, fraudulent,
                    misleading, or deceptive; (iii) is defamatory, abusive,
                    vulgar, hateful, harassing, obscene, profane, sexually
                    oriented, threatening, invasive of a person's privacy, or
                    otherwise volatile of any law; or (iv) promotes illegal or
                    harmful activities or substances; disrupt or interfere with
                    the security of, or otherwise abuse, the Site or any
                    service, systems resource, account, server, or network
                    connected to or accessible through the Site, or affiliated
                    or linked websites; disrupt or interfere with any other
                    User’s enjoyment of the Site, or affiliated or linked
                    websites; post, publish, submit or otherwise transmit
                    through or on the Site any viruses or other harmful,
                    disruptive, or destructive files; use, frame, or utilize
                    framing techniques to enclose our trademark, logo, or other
                    proprietary information (including, but not limited to,
                    images found on the Site, Content, any text, or the
                    layout/design of any page or form contained on a page)
                    without our express written consent; use meta tags or other
                    “hidden text” utilizing our name, trademark, or product name
                    without our express written consent; deep link to the Site
                    without our express written consent; create or use a false
                    identity on the Site, share your account information, or
                    allow any person besides yourself to use your account to
                    access the Site; collect or store personal data about
                    others; attempt to obtain unauthorized access to the Site or
                    portions of it that are restricted from general access; and
                    encourage or enable another User or individual to do any of
                    the foregoing prohibited activities. By accessing or using
                    this Site you agree to comply with all applicable local,
                    national, and international laws and regulations relating to
                    your use of or activities on the Site. To the extent
                    permissible by law, we will not be responsible or liable to
                    any third party for the User Content or accuracy of any
                    Content posted by you or any other User on the Site. We have
                    the right to remove any User Content or posting you make on
                    the Site if, in our sole discretion, such Content or posting
                    does not comply with the standards set out in these Terms.
                    If you do not agree to these Terms, you do not have our
                    consent to obtain information from or otherwise use the
                    Site. Failure to use the Site in accordance with these Terms
                    may subject you to civil or criminal penalties. You
                    acknowledge and agree that by accessing or using the site,
                    and/or downloading or posting any content from or on the
                    site, you have read, understand, and agree to be bound by
                    these terms whether or not you have created an account on
                    the site. If you do not agree to these terms, you have no
                    right to access or use the site. If you accept or agree to
                    these Terms on behalf of a company or other legal entity,
                    you represent and warrant that you have the authority to
                    bind that company or legal entity to these Terms and, in
                    such event, "you" or "your" will refer and apply to that
                    company or other legal entity.
                  </p>
                  <h3>Right to User Content</h3>
                  <p>
                    By making any User Content available to or through the Site,
                    you hereby grant "THE SITE" a non-exclusive, transferable,
                    sub-licensable, worldwide, royalty-free license to use,
                    copy, modify, create derivative works based upon,
                    distribute, publicly display, publicly perform, and
                    distribute your User Content in connection with operating
                    and providing products and services to you and/or other
                    Users, and also in connection with advertising of our
                    products and services.
                  </p>
                  <h3>Copyright</h3>
                  <p>
                    The Site, including all of the Content, is protected by
                    copyright, trademark, and other laws of the EU and foreign
                    countries. Unless expressly stated otherwise, we retain
                    other proprietary rights in all Content and products
                    available through the Site. Except as stated herein, none of
                    the Content may be copied, modified, reproduced,
                    distributed, republished, downloaded, performed, displayed,
                    posted, transmitted, sold, and or made into derivative works
                    in any form or by any means, including, but not limited to,
                    electronic, mechanical, photocopying, recording, or
                    otherwise without our prior written consent and permission
                    of the respective copyright owner. You may not, without our
                    express written permission or that of the respective
                    copyright owner (i) copy, upload, publish, post, submit, or
                    transmit any materials on any computer network or broadcast
                    or publications media; (ii) modify the materials; or (iii)
                    remove or alter any copyright or other proprietary notices
                    contained in the materials. You also may not (i) sell,
                    resell, or make commercial use of the Site, its Content,
                    services, or products obtained through the Site; (ii)
                    collect or use any product listings or descriptions; (iii)
                    make derivative uses of the Site or its Content; or (iv) use
                    any data mining, robots, or similar data gathering and
                    extracting methods. You are not conveyed any right or
                    license by implication, estoppel, or otherwise in or under
                    any of our patent, trademark, copyright, or other
                    proprietary rights of any third party.
                  </p>
                  <h3>Responsibility </h3>
                  <p>
                    As your guide I will support you in the process towards your
                    goalin the timeframe that we have together.
                    <br />
                    As the "Client" you are responsible to do the work in order
                    to see the desired changes in your Life. <br />
                    As guide I will work with you to identify problems, and
                    advise on the basis of my personal experiences in Life,
                    trainings, and academic studies.
                    <br />
                    As client you take the full responsibility for all of your
                    decisions and actions.
                    <br />
                    By agreeing with the T&C, you as the "Client" acknowledge
                    that I am not an acknowledged financial, medical, nor
                    judicia professional.
                  </p>
                  <h3>Privacy</h3>
                  <p>
                    As guide I will never share your personal details without
                    your consent.
                  </p>
                  <h3>Program</h3>
                  <p>
                    All Transmissions are planned on forehand. It is the
                    "Client's" responsibility to reschedule sessions at least 48
                    hours before the start, otherwise the session will be
                    considered expired.
                  </p>

                  <h3>Refund Policy </h3>
                  <p>
                    I honour you ("Client") for choosign with your Heart to
                    invest into yourself. That's why no refunds, exchanges, or
                    cancellations will be permitted.
                  </p>

                  <h3>Payment Plans</h3>
                  <p>
                    In case of failed payments, the "Client" will have 7 days to
                    fullfill the payment onbligation. All sessions will be
                    paused till payments are fullfilled. <br />
                    In case the "Client" does not fullfill the payment
                    obligation access to the Program & all materials will be
                    terminated.
                  </p>
                  <h3> Disclaimer </h3>
                  <p>
                    Every effort has been made to accurately represent this
                    Website and its information. Please utilize the resources &
                    information on this website at your own discretion. Nicol
                    Saha is not liable for misuse or misunderstanding of any
                    information or practices
                  </p>
                  <br />
                  <p>
                    <i>LAST UPDATED: DECEMBER 2022</i>
                  </p>
                  <div className='read-more-btn'>
                    <a className='btn-default my-5' href='/'>
                      <span> BACK</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TC;
