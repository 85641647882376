import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import AboutMe from '../elements/about/AboutMe';
import ScrollTop from '../common/footer/ScrollTop';
import { FiArrowRight } from 'react-icons/fi';
import { GiDandelionFlower } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import AboutMeTwo from '../elements/about/AboutMeTwo';
import NewsletterEN from '../elements/calltoaction/NewsletterEN';
import { BannerActivation } from '../utils/script';
import Slider from 'react-slick';

const BannerData = [
  {
    image: `${process.env.PUBLIC_URL}/images/about/temple_keeping.jpg`,
    title: 'Ritual Way of Life.',
    description: 'Seeing the Sacred in the Mundane through Beauty & Nature.',
  },
  {
    image: `${process.env.PUBLIC_URL}/images/about/temple_keeping_7.jpg`,
    title: 'Adoring the Feminine.',
    description: "Walking the Priestess' Path & uncovering Power in Softness.",
  },
  {
    image: `${process.env.PUBLIC_URL}/images/about/temple_keeping_5.jpg`,
    title: 'Entering Sacred Portals.',
    description: 'Weaving your Truest Voice into 3D Reality.',
  },
  {
    image: `${process.env.PUBLIC_URL}/images/about/temple_keeping_9.jpg`,
    title: 'Exploring Sensuality of Life.',
    description: 'In full Devotion to being Human.',
  },
  {
    image: `${process.env.PUBLIC_URL}/images/about/temple_keeping_8.jpg`,
    title: 'Forever Guided by the Stars.',
    description: 'Walking your Path with Trust & Surrender.',
  },
];

const HomeEN = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Mindset Transmissions' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />

        {/* Start Slider Area  */}
        <Slider
          className='slider-area slider-style-4 slider-dot rn-slick-dot rn-slick-arrow'
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className='single-slide'>
              <div
                className='height-950 bg_image'
                data-black-overlay='6'
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                }}
              >
                <div className='container'>
                  <div className='row row--30 align-items-center'>
                    <div className='col-12'>
                      <div className='inner text-center'>
                        <h1
                          className='title'
                          style={{
                            margin: 0,
                            fontSize: '8rem',
                            fontFamily: 'Magic',
                            color: 'white',
                          }}
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className='description'
                          dangerouslySetInnerHTML={{ __html: data.description }}
                          style={{
                            color: 'white',
                            textAlign: 'center',
                          }}
                        ></p>
                        <div className='button-group mt--30'>
                          <Link className='btn-default' to='/workwithme'>
                            🌹
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Slider Area  */}

        {/* Start Slider Area  */}
        <div className='slider-area slider-style-1 bg-transparent height-750'>
          <div className='container'>
            <div className='row align-items-center'>
              <div className='col-lg-7 col-xl-6 order-2 order-lg-1 mt_md--40 mt_sm--40'>
                <div className='inner text-start'>
                  <h1
                    className='title theme-gradient display-one'
                    style={{
                      margin: 0,
                      fontSize: '10rem',
                      fontFamily: 'Magic',
                    }}
                  >
                    BLOOM
                  </h1>
                  <ul className='list-icon'>
                    <li
                      style={{
                        margin: 0,
                        fontSize: '2.5rem',
                        fontFamily: 'Montserrat',
                        fontWeight: '900',
                      }}
                    >
                      <span className='icon'>
                        <GiDandelionFlower />
                      </span>{' '}
                      TEA CEREMONIALIST
                    </li>
                    <li
                      style={{
                        margin: 0,
                        fontSize: '2.5rem',
                        fontFamily: 'Montserrat',
                        fontWeight: '900',
                      }}
                    >
                      <span className='icon'>
                        <GiDandelionFlower />
                      </span>{' '}
                      ALCHEMIST
                    </li>
                    <li
                      style={{
                        margin: 0,
                        fontSize: '2.5rem',
                        fontFamily: 'Montserrat',
                        fontWeight: '900',
                      }}
                    >
                      <span className='icon'>
                        <GiDandelionFlower />
                      </span>{' '}
                      LOVER
                    </li>
                  </ul>
                  <div className='button-group mt--40'>
                    <Link
                      to='/workwithme'
                      className='btn-default round btn-icon'
                    >
                      OFFERINGS{' '}
                      <i className='icon'>
                        <FiArrowRight />
                      </i>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='col-lg-5 col-xl-6 order-1 order-lg-2'>
                <div>
                  <img
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '-75px',
                      right: '-70px',
                      height: '250px',
                      width: '250px',
                    }}
                    className='img-flowers w-80'
                    src='./images/about/single-flower-yellow.webp'
                    alt='flowers'
                  />
                  <img
                    style={{
                      padding: '0px',
                      borderStyle: 'solid',
                      position: 'relative',
                      borderWidth: '10px',
                      zIndex: '2',
                      borderImage:
                        'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                    }}
                    src={`/images/about/thee-ceremonie.webp`}
                    alt='Banner Images'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}

        <div style={{ maxWidth: '800px', margin: '15px auto' }}>
          <AboutMe />
          <AboutMeTwo />
        </div>

        {/* Start Call To Action Area  */}
        <div className='rwt-callto-action-area'>
          <div className='wrapper'>
            <NewsletterEN />
          </div>
        </div>
        {/* End Call To Action Area  */}
        <div
          className='content text-right container py-4'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            alignSelf: 'flex-end',
            justifyContent: 'center',
          }}
        >
          <span>
            <a
              style={{
                fontSize: '24px',
                fontFamily: 'Magic',
                padding: '0 18px',
                fontWeight: 'bold',
                color: '#e33fa1',
              }}
              target='_blank'
              href='https://www.instagram.com/bloomwithtea/'
            >
              INSTAGRAM
            </a>
          </span>
          <a target='_blank' href='https://www.instagram.com/bloomwithtea/'>
            <img
              style={{
                padding: '0 0 15px',
              }}
              width={window?.innerWidth > 890 ? '70%' : '100%'}
              src={`${process.env.PUBLIC_URL}/images/about/ig_grid.webp`}
              alt='ig grid'
            />
          </a>
        </div>
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default HomeEN;
