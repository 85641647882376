import React from 'react';
import Typed from 'react-typed';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import CalltoActionENTWO from '../elements/calltoaction/CalltoActionENTWO';
import ScrollTop from '../common/footer/ScrollTop';

const Reading = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />
        {/* Start Slider Area  */}
        <div className='slider-area slider-style-1 bg-transparent variation-2 height-750'>
          <div className='container' style={{ maxWidth: '92%' }}>
            <div className='row'>
              <div className='col-lg-7 order-2 order-lg-1 mt_md--40 mt_sm--40'>
                <div className='inner text-start'>
                  <h1
                    className='title theme-gradient display-two'
                    style={{ fontFamily: 'Magic' }}
                  >
                    ASTROLOGY READING
                    <br />
                  </h1>
                  <p className='description'>
                    Lets uncover your purpose and Sacred path by looking at the
                    stars at the time you were born!
                  </p>
                </div>
              </div>
              <div className='col-lg-5 order-1 order-lg-2'>
                <div className='thumbnail'>
                  <img
                    style={{
                      position: 'absolute',
                      zIndex: '1',
                      top: '-88px',
                      right: '-88px',
                      height: '250px',
                      width: '250px',
                    }}
                    className='img-flowers w-80'
                    src='./images/about/flowers-left-top-2.webp'
                    alt='flowers'
                  />
                  <img
                    style={{
                      padding: '0px',
                      borderStyle: 'solid',
                      position: 'relative',
                      borderWidth: '10px',
                      zIndex: '2',
                      borderImage:
                        'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                    }}
                    src='./images/about/astro_reading.webp'
                    alt='Banner Images'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Slider Area  */}
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <div className='rwt-callto-action-area'>
            <div className='wrapper'>
              <CalltoActionENTWO />
            </div>
          </div>
          <div style={{ padding: '25px' }}>
            <a class='inline-button' href='/workwithme'>
              {' '}
              <strong>&larr; ALL OFFERINGS</strong>
            </a>{' '}
          </div>
        </div>

        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default Reading;
