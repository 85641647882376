import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey='1'
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='0'>
            What is your connection with Tea?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='0'>
          <Card.Body>
            <p>
              ✨In the 29 years that I’ve been wandering on this beautiful Earth
              🌍 I’ve lived many lives. <br /> ✨From being trained as an
              academic researcher and epidemiologist (MSc), to studying yoga in
              India for a few years, to selling handmade crystal jewellery and
              practising Reiki in Nepal, to chasing my love for women’s health
              by studying midwifery, to working as a designer and developer
              because the healthcare world drove me insane (a lot to say about
              this...!), to finally finding the courage to claim my Life's
              mission and pave my own way by guiding women into the world of Tea
              & Ritual!
              <br /> ✨I could never keep steady ground beneath my feet. Always
              searching for something new to do, something new to learn. <br />
              ✨Move from one country to another one, jump from job to job, from
              study to study, from partner to partner.. Always looking for
              something that would give me inner peace. <br />✨ At the same
              time always fighting to survive.. Never feeling like I could be
              myself, forever hiding myself and always living on the edge...
              this continued till my body just yelled STOP. <br />
              ✨I landed in the hospital in a strange country and had panic
              attacks with the slightest things. These attacked made me face
              Death with suffocating fear, and then Tea came.. and I surrendered
              to Life. <br />
              ✨Tea kept whispering to me till I finally heard her calling while
              attending a conference and hearing the words Tea and Ceremony in
              one sentence! She helped me anchor into my body, drop into my
              heart, and live in true alignment.
              <br /> 🌟 Tea made me aware of something I could have never found
              anywhere else but deep inside my own being. <br />
              ✨There was no quick fix, no magic pil or formula, simply sitting
              with Tea every single day again and again.
              <br /> ✨Angry? Sad? Frustrated? Alone? Stressed? Overwhelmed?
              Whatever it is that I feel, I always know 1 thing… I’m never
              alone! <br />
              ✨Tea is always ready to welcome me. <br />
              ✨The switch from Tea as a drink to Tea as plant medicine felt to
              me like I image a butterfly feels when breaking free and spreading
              her wings for the first time. PURE magic! <br />
              🦋 You are here, so I KNOW that Tea has been whispering to you.
              Deep in your heart you know it too, are you ready to trust the
              calling? Are you ready? <br />
              🍵 You may not know why Tea has called you.. but there’s only one
              way to find out!
              <br />
              <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                The switch from Tea as a drink to Tea as a plant medicine is
                simply pure magic.. ✨
              </span>{' '}
            </p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='1'>
            What is so special about Tea?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='1'>
          <Card.Body>
            After water Tea is the most drank beverage in the world! But Tea is
            much more than just a drink, Tea is a very powerful and healing{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              plant medicine!
            </span>{' '}
            And in a world in which the male energies are dominating all around
            us .. This is visible in work, the longing for more, and never
            taking rest. Tea is our allie guiding us to{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              live in balans with ourselves and our environment
            </span>{' '}
            . There is so much more to tell you about Tea and why she's to
            special.. So I'd say, if she is calling you don't wait to start!
            <div className='read-more-btn mt--40'>
              <a
                target='_blank'
                className='btn-default'
                href='https://bloomwithtea.youcanbook.me/'
              >
                <span>TEA IS CALLING ME!</span>
              </a>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='2'>
            Why drink Tea daily?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='2'>
          <Card.Body>
            Aren't we all soooo sooo busy in these modern ages?! Busy with our
            relationships, busy with work, busy with all our responsibilities
            except our responsibilities to ourselves. Are we not important? Tea
            guides in creating space for yourself, in allowing for alignment
            within. Having a Tea practise in my eyes is a form of self-care and
            self-love! Simply by{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              sitting in meditative silence
            </span>{' '}
            with Tea as plant medicine. In the end it is completely up to you
            how often you sit with Tea, there is no right or wrong. But most
            people who start can't stop, so you are warned! <br />
            By opening yourself up daily, you will see the inner peace flowing
            over into your daily life. You'll experience{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              balance, harmony, and peace
            </span>{' '}
            throughout your day! ⚡
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='3'>
            What do I need?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='3'>
          <Card.Body>
            Tea, a bowl, a kettle to boil water, water, and the courage to open
            your heart! ✨ <br />
            <br /> <i> *You will be getting a handmade bowl upon signing up!</i>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='4'>
            How is Tea going to enrich my life?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='4'>
          <Card.Body>
            Tea is like a sweet Mother whom is always there for you. She does
            not judge, so you can always fully be yourself in her presence.{' '}
            <br /> <br />
            And while there is no magic pil that can make your life perfect in
            the blink of an eye. But what you can do is sitting in silence with
            Tea, and opening your heart every time more and more, to feel the
            love for yourself, all of the Universe and beyond! Slowing down our
            days will allow for{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              a more purposeful and balanced
            </span>{' '}
            life! You will be surprised to see how fast and steady Tea will seep
            into every area of you life.
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='5'>
            Help, I don't know anything about Tea!
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='5'>
          <Card.Body>
            Lucky you! You don't need to know anything about Tea. The fact that
            you're reading this and are feeling called in your heart is more
            than enough! Trust in it.{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              magic exists, and Tea is calling you to explore it together!
            </span>{' '}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='6'>
            I rather drink coffee...
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='6'>
          <Card.Body>
            Well.. where coffee gives you an instant shot of energy, Tea brings
            your body in balance. Tea gives you{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              peace, comfort, and anchors you into your body.
            </span>{' '}
            Tea also gives you a clear mind, just like coffee seems to do for
            some people. Huge benefit though... Tea has no side effects. No
            unbalancing feeling, no restlessness, no upset stomach. You still
            prefer to drink coffee and Tea does not call your Heart? I still
            love you! ❤
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant='link' eventKey='7'>
            Where do I start?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey='7'>
          <Card.Body>
            Do you feel called? You might not know why, but you can feel it in
            your heart? This means Tea has probaly planted a seed some time ago,
            and it is not ready to grow and bloom. It's time{' '}
            <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
              to explore
            </span>{' '}
            your journey with Tea! You can book a free 1-on-1 Tea intro session
            with me, or you can simply send me a message with any questions that
            you may have. I lovingly help you on your way. 🌟
            <div className='read-more-btn mt--40'>
              <a
                className='btn-default'
                href='https://bloomwithtea.youcanbook.me/'
              >
                <span>I WANT TO START!</span>
              </a>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
