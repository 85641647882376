import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const ServiceList = [
  {
    icon: 'CLARITY',
    title: 'Your Desires',
    description:
      'Getting crystal clear how you want to feel before, during, and after childbirth.',
  },
  {
    icon: 'POWER',
    title: 'Your Rawness',
    description:
      'Uncovering your Wild Woman and finding the courage to show her in all her glory.',
  },
  {
    icon: 'COMMUNITY',
    title: 'Your Connection',
    description:
      'Remembering that you are not alone, and you never will be alone. We are in this togehter.',
  },
  {
    icon: 'SACREDNESS',
    title: 'Your Surrender',
    description:
      'Holding space for the Sacred to unfold, while backing it with all the Science.',
  },
];

const ServiceFour = ({ textAlign, serviceStyle }) => {
  return (
    <div className='row row--15 service-wrapper'>
      {ServiceList.map((val, i) => (
        <div className='col-lg-3 col-md-6 col-sm-6 col-12' key={i}>
          <ScrollAnimation
            animateIn='fadeInUp'
            animateOut='fadeInOut'
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div
                className='icon'
                style={{ fontFamily: 'Magic', fontSize: '2.5rem' }}
              >
                <div className='line'></div>
                {val.icon}
              </div>
              <div className='content'>
                <h4 className='title'>{val.title}</h4>
                <p
                  className='description b1 color-gray mb--0'
                  dangerouslySetInnerHTML={{ __html: val.description }}
                ></p>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceFour;
