import React, { useState } from 'react';
import { FiArrowRight } from 'react-icons/fi';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [status, setStatus] = useState(null);

  const FORM_URL = 'https://app.convertkit.com/forms/3083766/subscriptions';

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    try {
      const response = await fetch(FORM_URL, {
        method: 'post',
        body: data,
        headers: {
          accept: 'application/json',
        },
      });

      setEmail('');
      const json = await response.json();

      if (json.status === 'success') {
        setStatus('SUCCESS');
        return;
      }
    } catch (err) {
      setStatus('ERROR');
      console.log(err);
    }
  };

  const handleEmailChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };

  const handleNameChange = (event) => {
    const { value } = event.target;
    setName(value);
  };

  return (
    <div>
      {status === 'SUCCESS' && (
        <>
          <p style={{ color: '#181818' }}>
            <strong> Thank you angel {name ? `, ${name}` : ''} ❤!</strong>
          </p>
          <p style={{ color: '#181818' }}>
            Please check your inbox for the confirmation email!✨
          </p>
        </>
      )}
      {status === 'ERROR' && (
        <>
          <p style={{ color: '#181818' }}>Oops, something went wrong...</p>
          <p style={{ color: '#181818' }}>
            Please,{' '}
            <button
              style={{ color: '#181818' }}
              onClick={() => setStatus(null)}
            >
              try again.
            </button>
          </p>
        </>
      )}
      {status === null && (
        <form onSubmit={handleSubmit}>
          <div className='form-group'>
            <input
              aria-label='Your first name'
              name='fields[first_name]'
              placeholder='Your first name'
              type='text'
              onChange={handleNameChange}
              value={name}
            />
          </div>
          <div className='form-group'>
            <input
              aria-label='Your email address'
              name='email_address'
              placeholder='Your email address'
              required
              type='email'
              onChange={handleEmailChange}
              value={email}
            />
          </div>
          <button style={{ border: 'none', backgroundColor: 'transparent' }}>
            <div
              className='call-to-btn text-center'
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                margin: '0 !important',
              }}
            >
              <a className='btn-default btn-icon'>
                RECEIVE
                <i className='icon'>
                  <FiArrowRight />
                </i>
              </a>
            </div>
          </button>
        </form>
      )}
    </div>
  );
};

export default SubscribeForm;
