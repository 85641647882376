import React from 'react';
import Typed from 'react-typed';

const About = () => {
  return (
    <div className='rwt-about-area rn-section-gap'>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-12 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <h2 className='title'>
                  THE WAY OF TEA
                  <br />{' '}
                  <Typed
                    className='theme-gradient'
                    strings={[
                      'Ritual.',
                      'Connection.',
                      'Abundance.',
                      'Inner peace.',
                      'Harmony.',
                      'Love.',
                      'Alignment.',
                      'Cha Dao.',
                      'Inner Goddess.',
                      'Magic.',
                    ]}
                    typeSpeed={80}
                    backSpeed={5}
                    backDelay={1000}
                    loop
                  />{' '}
                </h2>
                <p style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                  Gorgeous Being! ✨ Tea has led you to me, and I am beyond
                  excited to welcome you into this Sacred space that we will be
                  co-creating together with Camelia sinensis (aka Tea)! Tea has
                  been worked with ceremonially as medicine for thousands of
                  years. As we sip this Earthy elixir of the Heart, we begin to
                  receive healing, activations, multi-dimensional connection,
                  and clearance of stagnant Life energy. Together we will
                  create...
                </p>

                <p>
                  🌟 A safe space to{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    connect
                  </span>{' '}
                  to your own inner wisdom, and
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    {' '}
                    remember{' '}
                  </span>
                  the medicine that you are through cultivating an intimate
                  relationship with the Spirit of Tea!
                  <br />
                  🌟 A safe space to be held as you{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    expand
                  </span>{' '}
                  into your multidimensionality through ceremonial devotion.
                  <br />
                  🌟 A safe space to explore purifying your mind & body to
                  becoming a clear vessel for your{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    unique codes.
                  </span>
                  <br />
                  🌟 A safe space to bridge{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    Heaven and Earth{' '}
                  </span>
                  and the multi-dimensional and the material.
                  <br />
                  🌟 A safe space for you to remember that you are{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    home, worthy, and loved
                  </span>{' '}
                  wherever you are!
                  <br />
                  🌟 A space to let go of all the expectations you put on
                  yourself and simply surrender to the divinely orchestrated
                  chaos of Life as a Human.
                  <br />
                  🌟 A space to remember that you are 100% pure love, warm,
                  radiating, illuminating, joyful, beautiful, gracious,
                  glorious,{' '}
                  <span style={{ fontFamily: 'PT Serif', fontSize: '20px' }}>
                    LOVE!
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
