import React from 'react';
import Typed from 'react-typed';
import { Link } from 'react-router-dom';

const AboutMe = () => {
  return (
    <div style={{ padding: '50px 0 0' }}>
      <h2 style={{ textAlign: 'center' }}>I’M SO HONOURED YOU’RE HERE!</h2>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-5'>
            <div className='thumbnail'>
              <img
                style={{
                  position: 'absolute',
                  zIndex: '1',
                  top: '-100px',
                  left: '-100px',
                  height: '250px',
                  width: '250px',
                }}
                className='img-flowers w-80'
                src='./images/about/flowers-left-top.webp'
                alt='flowers'
              />
              <img
                style={{
                  borderStyle: 'solid',
                  position: 'relative',
                  borderWidth: '10px',
                  zIndex: '2',
                  borderImage:
                    'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                }}
                className='w-100'
                src='./images/about/aboutme.webp'
                alt='About Images'
              />
            </div>
          </div>

          <div className='col-lg-7 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <p
                  style={{
                    fontFamily: 'PT Serif',
                    fontSize: '20px',
                    marginBottom: '10px',
                  }}
                >
                  Gifted with the beautiful name Bloom,{' '}
                  <strong>
                    I am a wild Woman, a soft Rose, a stormy Sea, a grounded
                    Tree, a mystic Mermaid, a Stargazer, a Tea Ceremonialist,
                    and a Keeper of the Sacred by Heart.{' '}
                  </strong>
                </p>
                <p>
                  Tea guided me into the World of Ritual, she guided me into
                  uncovering my innate worthiness, my balance, my inner peace,
                  and my capacity of opening up to Wholeness and Abundance on
                  all levels of my Being. She helped me change my Life, and I
                  cannot wait to help you change yours too! ⚡
                </p>
                <br />
                <p>
                  {' '}
                  <strong>
                    My mission is to guide as many beautiful souls as possible
                    into the Sacred World of Ritual!
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 style={{ textAlign: 'center', padding: '65px 5px' }}>
        {' '}
        Capricorn Sun, with a Cancer Rising & Gemini Moon!
      </h2>
    </div>
  );
};

export default AboutMe;
