import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import TestimonialThreeEN from '../elements/testimonial/TestimonialThreeEN';
import SectionTitle from '../elements/sectionTitle/SectionTitle';
import MissionEN from '../elements/about/MissionEN';
import AboutEN from '../elements/about/AboutEN';
import TimelineOneEN from '../elements/timeline/TimelineOneEN';
import CalltoActionTwoEN from '../elements/calltoaction/CalltoActionTwoEN';
import ScrollTop from '../common/footer/ScrollTop';

const TeaCeremony = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />
        <div style={{ maxWidth: '800px', margin: '0 auto' }}>
          <AboutEN />

          {/* Start Service Area  */}
          <div id='offering' className='rn-service-area'>
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-left'
                    radiusRounded=''
                    title='THE FLOW'
                    description=''
                  />
                  <p>
                    <strong
                      style={{ fontFamily: 'PT Serif', fontSize: '20px' }}
                    >
                      Over a period of 5 weeks we will be sitting together with
                      Tea, every week will include:
                    </strong>{' '}
                    <br />
                    🌟 1-on-1 session of 1h on Tea & Ritual <br />
                    🌟 2x virtual Tea sit <br />
                    🌟 Guidebook
                    <br /> <br />
                    <strong>BONUS:</strong> FIRE ceremony to set our intentions
                    for our time together. <br />
                    <strong>BONUS:</strong> Your very own handmade Tea bowl!
                    <br /> <strong>BONUS:</strong> Access to Cosmic Answer. A
                    deck consisting of 44 cards to connect to Self and Nature!
                    (Available in EN & NL) <br /> <strong>BONUS:</strong> Custom{' '}
                    <i>Prosperity Astrology Chart Reading!</i>
                  </p>
                </div>
              </div>
              {/* Start Timeline Area  */}
              <div className='rwt-timeline-area mb--40'>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <TimelineOneEN />
                    </div>
                  </div>
                </div>
              </div>
              {/* End Timeline Area  */}
            </div>
          </div>
          {/* End Service Area  */}

          {/* End About Area  */}
          {/* Start Call To Action Area  */}
          <div className='rwt-callto-action-area '>
            <div className='wrapper'>
              <CalltoActionTwoEN />
            </div>
          </div>
          {/* End Call To Action Area  */}

          {/* Start Testimonial Area  */}
          <div
            id='testimonials'
            className='rwt-testimonial-area rn-section-gap'
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-12'>
                  <SectionTitle
                    textAlign='text-center'
                    radiusRounded=''
                    subtitle='Tea Friends'
                    title='Sitting in silence with a cup of plant medicine opens the door to yourself, for everyone in a unique and often surprising way!'
                  />
                </div>
              </div>
              {/*   <TestimonialThreeEN teamStyle='' /> */}
            </div>
          </div>
          {/* End Testimonial Area  */}

          {/* Start FAQ Area   */}
          <div id='faq'>
            <MissionEN />
            <div style={{ padding: '25px' }}>
              <a class='inline-button' href='/workwithme'>
                {' '}
                <strong>&larr; ALL OFFERINGS</strong>
              </a>{' '}
            </div>
          </div>
          {/* Start FAQ Area  */}
        </div>
        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default TeaCeremony;
