import React, { useState, useRef } from 'react';
import Logo from '../../elements/logo/Logo';
import NavEN from './NavEN';
import MobileMenu from './MobileMenu';
import Darkmode from './Darkmode';
import useStickyHeader from './useStickyHeader';
import { FiMenu } from 'react-icons/fi';

const HeaderTwoEN = ({ btnStyle, HeaderStyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false);
  const onCanvasHandler = () => {
    setOffcanvasShow((prev) => !prev);
  };

  const ref = useRef();
  let [check, setCheck] = useState(true);
  const sticky = useStickyHeader(50);
  const headerClasses = `header-default ${sticky && check ? 'sticky' : ''}`;

  return (
    <>
      <header
        ref={ref}
        className={`rn-header header-default header-left-align ${HeaderStyle} ${headerClasses}`}
      >
        <div className='container position-relative'>
          <div className='row align-items-center'>
            <div className='col-lg-9 col-md-6 col-4 position-static'>
              <div className='header-left d-flex'>
                <div
                  className='header-left d-flex'
                  style={{
                    flexDirection: 'row',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '150px',
                  }}
                >
                  <Logo
                    image={`${process.env.PUBLIC_URL}/images/logo/logo_dark.webp`}
                    image2={`${process.env.PUBLIC_URL}/images/logo/logo_light.webp`}
                  />
                </div>
                <nav className='mainmenu-nav d-none d-lg-block'>
                  <NavEN />
                </nav>
              </div>
            </div>

            <div className='col-lg-3 col-md-6 col-8'>
              <div className='header-right' style={{ flexWrap: 'nowrap' }}>
                {' '}
                <div
                  className='header-btn'
                  style={{
                    display: 'flex',
                    whiteSpace: 'nowrap',
                    fontWeight: '800',
                  }}
                >
                  <a
                    className={`btn-default ${btnStyle}`}
                    target='_blank'
                    href='https://linktr.ee/bloomwithtea'
                  >
                    🌹 CONNECT
                  </a>
                </div>
                <div className='mobile-menu-bar ml--5 d-block d-lg-none'>
                  <div className='hamberger'>
                    <span
                      className='hamberger-button'
                      onClick={onCanvasHandler}
                    >
                      <FiMenu />
                    </span>
                  </div>
                </div>
                <Darkmode />
              </div>
            </div>
          </div>
        </div>
      </header>
      <MobileMenu show={ofcanvasShow} onClose={onCanvasHandler} />
    </>
  );
};
export default HeaderTwoEN;
