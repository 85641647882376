import React from 'react';

const AboutMeThree = () => {
  return (
    <div className=''>
      <div className='container'>
        <div className='row row--30 align-items-center'>
          <div className='col-lg-4'>
            <div className='thumbnail'>
              <img
                style={{
                  borderStyle: 'solid',
                  borderWidth: '10px',
                  borderImage:
                    'linear-gradient(62deg, rgb(142, 197, 252), rgb(224, 195, 252)) 1',
                }}
                className='w-100'
                src='./images/about/temple_keeper_2.webp'
                alt='About Images'
              />
            </div>
          </div>
          <div className='col-lg-8 mt_md--40 mt_sm--40'>
            <div className='content'>
              <div className='section-title'>
                <p>
                  Tea kept whispering to me till I finally heard her calling
                  while attending a conference and hearing the words Tea and
                  Ceremony in one sentence! She helped me anchor into my body,
                  drop into my Heart, and live in true alignment. 🌟 Tea made me
                  aware of something I could have never found anywhere else but
                  deep inside my own Being. There was no quick fix, no magic pil
                  or formula, simply sitting with Tea every single day again and
                  again and again.
                </p>
                <p>
                  Angry? Sad? Frustrated? Alone? Stressed? Overwhelmed?
                  Disconnected? Whatever it is that I feel, I always know 1
                  thing… I’m never alone! Tea is always ready to welcome me. The
                  switch from Tea as a drink to Tea as plant medicine felt to me
                  like I image a butterfly feels when breaking free and
                  spreading her wings for the first time.{' '}
                  <strong>PURE magic!</strong> 🦋
                </p>
                <p
                  style={{
                    fontFamily: 'PT Serif',
                    fontSize: '20px',
                    marginBottom: '10px',
                  }}
                >
                  You are here, so I KNOW that Tea has been whispering to your
                  Heart. Are you ready to trust the calling? Are you ready? 🍵
                  You may not know why Tea has called you.. but there’s only one
                  way to find out! ✨
                </p>

                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutMeThree;
