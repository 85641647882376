import React from 'react';
import Typed from 'react-typed';
import AccordionOneEN from '../accordion/AccordionOneEN';

const missionEN = () => {
  return (
    <div className='rn-company-mission-are rn-section-gap'>
      <div className='container'>
        <div className='row row--30'>
          <div className='col-lg-6'>
            <div className='mission-title'>
              <h2 className='title'>
                FAQ
                <br />{' '}
                <Typed
                  className='theme-gradient'
                  strings={[
                    'Ask.',
                    'Learn.',
                    'Understand.',
                    'Feel.',
                    'Integrate.',
                    'Become.',
                  ]}
                  typeSpeed={80}
                  backSpeed={5}
                  backDelay={1000}
                  loop
                />
              </h2>
              <p>
                Answers to the most frequently asked questions! If yours if not
                in the list, don't hesitate to send me a message.
              </p>
            </div>
          </div>
          <div className='col-lg-6 mt_md--30 mt_sm--30'>
            <AccordionOneEN customStyle='' />
          </div>
        </div>
      </div>
    </div>
  );
};
export default missionEN;
