import React from 'react';
import Copyright from './footer/Copyright';
import HeaderTwoEN from './header/HeaderTwoEN';

const Layout = ({ children }) => {
  return (
    <>
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />
        {children}
        <Copyright />
      </main>
    </>
  );
};
export default Layout;
