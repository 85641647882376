import React, { useState, useEffect } from 'react';
import PortfolioData from './data.json';
import Item from './Item';

const alldata = PortfolioData;
const One = ({ Column }) => {
  const [getAllItems] = useState(alldata);
  const [dataVisibleCount, setDataVisibleCount] = useState(4);
  const [visibleItems, setVisibleItems] = useState([]);
  useEffect(() => {
    setVisibleItems(getAllItems.filter((item) => item.id <= dataVisibleCount));
  }, []);

  return (
    <>
      <div className='row row--15' style={{ justifyContent: 'center' }}>
        {visibleItems.map((item) => (
          <div key={item.id} className={Column} style={{ width: '50%' }}>
            <Item portfolio={item} />
          </div>
        ))}
      </div>
    </>
  );
};

export default One;
