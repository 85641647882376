import React from 'react';
import { Link } from 'react-router-dom';

const NavEN = ({ onClose }) => {
  return (
    <ul className='mainmenu'>
      <li onClick={onClose}>
        <Link to={'/'}>HOME</Link>
      </li>
      <li onClick={onClose}>
        <Link to={'/about'}>ABOUT</Link>
      </li>
      <li className='has-droupdown' onClick={onClose}>
        <Link to={'/workwithme'}>OFFERINGS</Link>
        <ul className='submenu'>
          <li>
            <Link to='/deck'>AFFIRMATION DECK</Link>
          </li>
          <li>
            <Link to='/astrologyreading'>ASTROLOGY READINGS</Link>
          </li>
          <li>
            <Link to='/teaceremony'>TEA CEREMONY</Link>
          </li>
        </ul>
      </li>
      <li onClick={onClose}>
        <Link to={'/connect'}>GET IN TOUCH</Link>
      </li>
    </ul>
  );
};
export default NavEN;
