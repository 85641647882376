import React from 'react';
import SEOEN from '../common/SEOEN';
import HeaderTwoEN from '../common/header/HeaderTwoEN';
import Copyright from '../common/footer/Copyright';
import ContactFormEN from '../elements/contact/ContactFormEN';
import SectionTitleTwo from '../elements/sectionTitle/SectionTitleTwo';
import ScrollTop from '../common/footer/ScrollTop';

const Connect = () => {
  return (
    <>
      <SEOEN title='Tea Ceremony & Ritual Way of Life' />
      <main className='page-wrapper'>
        <HeaderTwoEN btnStyle='round' HeaderStyle='header-not-transparent' />

        <div id='connect' className='contact-area mb-4'>
          <div className='container'>
            <div className='row'>
              <br />
            </div>
            <div className='row'>
              <div className='col-lg-12'>
                <SectionTitleTwo textAlign='text-left' />
                <p
                  style={{
                    fontFamily: 'PT Serif',
                    fontSize: '20px',
                    marginBottom: '10px',
                  }}
                >
                  I would love to connect, to hear from you, about you! What are
                  your dreams? What is in the way of realising them? Maybe you
                  feel like I can help you in a way... Let's connect and see if
                  we can co-create a space for your Truest Self to{' '}
                  <strong>BLOOM!</strong> Can't wait to hear from you!
                </p>
              </div>
            </div>
            <div className='row row--30 mt--40'>
              <div className='col-lg-6 order-2 oder-lg-1 mt_md--30 mt_sm--30'>
                <ContactFormEN formStyle='contact-form-1' />
              </div>
              <div className='col-lg-6 order-1 oder-lg-2'>
                <div className='thumbnail'>
                  <img
                    className='radius w-100'
                    src='./images/about/love.png'
                    alt='temple keeper'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Copyright />
        <ScrollTop />
      </main>
    </>
  );
};
export default Connect;
